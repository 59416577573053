/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import useSWR from "swr";

import { IQueryOne } from "@marapp/earth-shared";

import { BaseAPIService, metaDeserializer } from "../services/APIBase";
import { encodeQueryToURL } from "../utils";

interface IOptions {
  fetcher?: (url: string) => Promise<any>;
  transformResponse?: (response: any) => any;
  swrOptions?: {
    [key: string]: any;
  };
}

const defaultOptions: IOptions = {
  fetcher: (url) => BaseAPIService.request(url, undefined, metaDeserializer),
  transformResponse: (response) => response,
  swrOptions: {},
};

export function useFetchOne(
  url: string,
  query: IQueryOne,
  passedOptions?: IOptions
) {
  const options = {
    ...defaultOptions,
    ...passedOptions,
  };
  const fetcher = (url) => {
    return options.fetcher(url).then(options.transformResponse);
  };
  const finalUrl = encodeQueryToURL(url, query);

  const { data: rawData = {}, ...rest } = useSWR(
    finalUrl,
    fetcher,
    options.swrOptions
  );
  const { data, meta } = rawData;

  return {
    data,
    meta,
    ...rest,
  };
}
