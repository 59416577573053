import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import React from 'react';
import { useTranslation } from 'react-i18next';

interface INoSearchResultsText {
  className?: string;
  search: string;

  [any: string]: any;
}

export function NoSearchResultsText(props: INoSearchResultsText) {
  const { className, search, ...rest } = props;

  const { t } = useTranslation();

  return (
    <Box className={className} px={2} py={1} {...rest}>
      {search ? (
        <Typography>{t('No search results', { value: search })}</Typography>
      ) : (
        <Typography>{t('No search results, no value')}</Typography>
      )}
    </Box>
  );
}
