module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://ae53e50fc35a45499f8d8d60b7ed2726@o4505205380874240.ingest.sentry.io/4505221941100544","environment":"Staging UN Biodiversity Lab","sampleRate":1,"ignoreErrors":["@math.gl/web-mercator: assertion failed","Fetch is aborted","NS_ERROR_FILE_NO_DEVICE_SPACE","ResizeObserver loop limit exceeded","ResizeObserver loop completed with undelivered notifications."]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-3JSBPFJQN2"],"gtagConfig":{},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
