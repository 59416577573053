/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import { get } from 'lodash';
import qs from 'query-string';
import React from 'react';
import { replace } from 'redux-first-router';
import { Deserializer } from 'ts-jsonapi';
import urljoin from 'url-join';

import { MAP_BASE_URL } from '../config';

const DeserializerService = new Deserializer({
  keyForAttribute: (attribute: any) => {
    return attribute;
  },
});

/**
 * Route to target URL in case of success/failure.
 */

export const routeToPage = ({
  basePath,
  targetPath,
}: {
  basePath?: string;
  targetPath?: string;
}) => {
  const base = basePath ? basePath : MAP_BASE_URL;
  const target = targetPath ? urljoin(base, targetPath) : base;

  replace(target);
};

export const parseHintBold = (text: string = '') => {
  return text
    .split(/({{.+?}})/)
    .map((term) =>
      term.startsWith('{{') && term.endsWith('}}') ? (
        <b>{term.replace('{{', '').replace('}}', '')}</b>
      ) : (
        term
      )
    );
};

export const slashFlip = (text: string = '') =>
  text.indexOf('/') > 0 ? `${text.split('/')[1]} (${text.split('/')[0]})` : text;

/**
 * Deserializer
 */
export const deserializeData = (data) => DeserializerService.deserialize(data);

export const getUrlQueryParams = () => {
  return qs.parse(windowPropertySSR('location.search', ''));
};

/**
 * Load "browser globals" like window (during SSR).
 * See: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
 */
export const windowPropertySSR = (path?: string, fallback: any = null) =>
  typeof window !== 'undefined' ? (path ? get(window, path, fallback) : window) : fallback;

/**
 * Safely allow gtag event tracking
 */
export const trackGtagEvent = (event: string = 'click', data?) => {
  if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
    // TODO: remove/deactivate this function when alternative approach for collecting analytics is activated -
    // See recordEvent method in packages/earth-map/src/analytics/gtag.ts
    window.gtag('event', event, { ...data });
  }
};
