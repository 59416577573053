import React, { useState } from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconClose from 'mdi-material-ui/Close';
import List from '@researchgate/react-intersection-list';
import { Input } from '../input';

import { IResponseMany, IQueryMany } from '../../interfaces';

const useStyles = makeStyles(theme => ({
  selectPaper: {
    background: theme.palette.grey['600'],
    maxHeight: '40vh',
    overflowY: 'auto',
    listStyle: 'none',
  },
  chip: {
    marginRight: 4,
    marginBottom: 4,
    maxWidth: '100%',
  },
}));

interface IOption {
  value: string;
  [any: string]: any;
}

interface ISelectProps {
  className?: string; // custom className
  getOptionLabel?: (o: any) => string; // get label to be displayed in the options list
  getOptionSelected?: (o: any, v: any) => boolean; // function to determine if an option is selected from the value prop
  multiple?: boolean; // allow multiple selection
  allowAdd?: boolean; // allow adding to the list
  options?: IOption[];
  isLoading?: boolean;
  label?: any;
  placeholder?: any;

  // react-hook-form or custom control
  value?: any;
  onChange: (ev: any) => void;
  onBlur?: (ev: any) => void;
  inputRef?: any;
  error?: string;

  // async props
  fetcher?: (query: IQueryMany) => IResponseMany;
  query?: (search: string) => IQueryMany;

  [any: string]: any;
}

interface IAsyncListBox {
  children: any; // options
  hasMore: boolean; // flag to determine if new options should be loaded
  nextPage: (payload: any) => any; // function to be called to fetch new options
}

/**
 * Our application receives the options array in the following format
 * { name: string, value: string }[] - most of the times
 * { label: string, value: string }[] - some cases
 * string[] - rarely
 * note that we CANNOT return an object
 * */
const defaultGetOptionLabel = option => {
  if (!option) {
    return '';
  }
  if (typeof option === 'object') {
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    } else if (option.name) {
      return option.name;
    } else if (option.label) {
      return option.label;
    }
    // Value selected with enter, right from the input
  } else if (typeof option === 'string') {
    return option;
  } else {
    return '';
  }
};

const defaultGetOptionSelected = (option, value) => {
  if (!value) {
    return false;
  }
  if (value.id) {
    return option.id === value.id;
  } else if (option.value) {
    if (value.value) {
      return option.value === value.value;
    } else {
      return option.value === value;
    }
  } else {
    return option === value;
  }
};

const filter = createFilterOptions();

const PAGE_SIZE = 30;

const useAsyncListBoxStyles = makeStyles(theme => ({
  // Apply MUI autocomplete list container styles
  root: {
    margin: 0,
    marginRight: theme.spacing(-1),
    padding: theme.spacing(1, 0),
    overflow: 'auto',
    listStyle: 'none',
    maxHeight: '40vh',
  },
}));

const AsyncListBoxComponent = React.memo(
  React.forwardRef((props: IAsyncListBox, ref) => {
    const {
      children,
      hasMore,
      nextPage,
      ...rest // material ui autocomplete options container props
    } = props;

    const classes = useAsyncListBoxStyles();

    return (
      <div {...rest} className={`${classes.root} ${rest.className}`}>
        <List
          awaitMore={!!hasMore}
          pageSize={PAGE_SIZE}
          itemCount={children?.length}
          onIntersection={nextPage}
          renderItem={index => children[index]}
        />
      </div>
    );
  })
);

const MuiSelect = React.forwardRef((props: ISelectProps, ref: any) => {
  const {
    // dedicated props
    multiple,
    allowAdd = false,
    options,

    // react-hook-form
    onChange,
    onBlur,
    value,

    inputRef,
    error,

    // extra
    className,
    placeholder,
    label,
    getOptionLabel = defaultGetOptionLabel,
    getOptionSelected = defaultGetOptionSelected,
    isLoading: isLoadingProp,

    // async props
    fetcher,
    query,
    ...rest
  } = props;

  const classes = useStyles();
  const [searchValue, setSearchValue] = useState(''); // search value
  const isAsync = !!fetcher;
  const finalFetcher =
    fetcher ||
    (() => ({
      data: null,
      awaitMore: null,
      nextPage: null,
    }));

  /**
   * In case there is a value already selected, display the initial options, instead of options matching the current value.
   * For this we need to perform an empty query search to our fetcher
   *
   * getOptionLabel - maps each option object to a given string
   * defaultGetOptionLabel = (option) => option?.name || option?.label || option;
   * */
  const searchTerm = isAsync && searchValue === getOptionLabel(value) ? '' : searchValue;
  const { data, awaitMore, nextPage } = finalFetcher(isAsync ? query(searchTerm) : null);
  const isLoading = isAsync ? !data : isLoadingProp;

  /**
   * Extra properties used for async options loading
   * */
  const autoCompleteProps = {
    loading: isLoading, // loading state indicator
    ...(isAsync && {
      loading: !data, // loading state indicator
      inputValue: searchValue, // local state - keep 'search term' and load options based on current value
      onInputChange: (ev, newValue) => setSearchValue(newValue), // update local state
      options: data || [], // dynamically loaded options

      /**
       * Options container performing pagination request
       * */
      ListboxComponent: AsyncListBoxComponent,
    }),
    ...(isLoading && {
      closeIcon: <CircularProgress size={16} />,
    }),
    ListboxProps: {
      className: 'marapp-qa-select-options-container',
      ...(isAsync && {
        hasMore: awaitMore,
        nextPage,
      }),
    },
  };

  return (
    <>
      <Autocomplete
        classes={{
          paper: classes.selectPaper,
        }}
        className={`marapp-qa-select ${className}`}
        closeIcon={<IconClose className="marapp-qa-select-clear" fontSize="small" />}
        ChipProps={{ className: `${classes.chip} marapp-qa-select-chip` }}
        disablePortal={true}
        fullWidth={true}
        disableCloseOnSelect={!!multiple}
        filterSelectedOptions={!!multiple}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // if allowed, suggest the creation of a new value
          if (allowAdd && params.inputValue !== '') {
            if (!filtered.find(f => f.value === params.inputValue)) {
              filtered.push({
                inputValue: params.inputValue,
                label: params.inputValue,
                value: params.inputValue,
              });
            }
          }

          return filtered;
        }}
        multiple={multiple}
        freeSolo={!!allowAdd}
        selectOnFocus={!!allowAdd}
        handleHomeEndKeys={!!allowAdd}
        options={options}
        value={value}
        onBlur={onBlur}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        onChange={(newValueIndex: any, newValue: IOption | IOption[]) => {
          if (Array.isArray(newValue)) {
            // check each newValue, wrap if it is just a string
            onChange(newValue?.map(v => (typeof v === 'object' ? v : { value: v, label: v })));
          } else {
            onChange(newValue);
          }
        }}
        ref={ref}
        renderInput={params => (
          <Input {...params} label={label} placeholder={placeholder} error={error} />
        )}
        {...autoCompleteProps}
        {...rest}
      />
    </>
  );
});

export default React.memo(MuiSelect);
