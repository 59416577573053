/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import React from "react";

// @ts-ignore
import BackgroundImage from "../../assets/404_bg.jpg";

interface IProps {
  type: React.ReactNode | string;
  message: React.ReactNode | string;
  children?: any;
  ctaDescription?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "relative",
    "& >div": {
      zIndex: 1,
    },
    "& a": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));

export const ErrorTemplate = (props: IProps) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.root} marapp-qa-notfound`}
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      p={6}
    >
      <Container maxWidth="sm">
        {typeof props.type === "string" ? (
          <Typography
            align="center"
            className="marapp-qa-status"
            color="secondary"
            paragraph={true}
            variant="h1"
          >
            {props.type}
          </Typography>
        ) : (
          props.type
        )}

        <Typography align="center" className="marapp-qa-text" paragraph={true}>
          {props.message}
        </Typography>

        <div className="not-found--links">
          {props.ctaDescription && (
            <div className="not-found--links--title">
              {props.ctaDescription}
            </div>
          )}
          {props.children}
        </div>
      </Container>
    </Box>
  );
};
