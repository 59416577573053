import MuiListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/core/styles/makeStyles';

import React from 'react';
import classnames from 'classnames';

interface IListItemProps {
  className?: string;
  onClick?: (arg?: any) => any;
  primary: string | React.ReactNode;
  secondary?: string | React.ReactNode;
  secondaryAction?: React.ReactNode;
  selected?: boolean;
  linkTo?: string;
  linkComponent?: any;
  noWrap?: boolean;

  [any: string]: any;
}

const useStyles = makeStyles(theme => ({
  root: (props: IListItemProps) => {
    const rootStyles: any = {
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:last-child': {
        borderBottom: 'none',
      },
    };

    if (props?.secondaryAction) {
      rootStyles.paddingRight = theme.spacing(9);
    }

    return rootStyles;
  },

  // needed for ul>li structure (on Layers tab)
  container: {
    '&:last-child div': {
      borderBottom: 'none',
    },
  },
}));

export function ListItem(props: IListItemProps) {
  const {
    className,
    linkComponent,
    linkTo,
    onClick,
    primary,
    secondary,
    secondaryAction,
    selected,
    noWrap = true,
    ...rest
  } = props;

  const classes = useStyles(props);

  const listItemProps: any = {
    button: true,
    onClick,
    classes: { container: classes.container },
    className: classnames(classes.root, className, 'marapp-qa-listitem'),
    selected,

    // ToDo change this condition once switching to an unique router throughout map and admin
    component: linkComponent || 'div',
    ...(linkTo && {
      to: linkTo,
    }),
  };

  return (
    <MuiListItem {...listItemProps} {...rest}>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{ className: 'marapp-qa-list-item-title', noWrap: noWrap }}
        secondaryTypographyProps={{ className: 'marapp-qa-list-item-description', noWrap: noWrap }}
      />

      {secondaryAction && <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>}
    </MuiListItem>
  );
}
