/*
  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconClose from "mdi-material-ui/Close";

import { validEmail } from "../../index";

export interface EmailInputProps {
  placeholder?: string;
  isDisabled?: boolean;
  className?: string;
  label?: string;
  [key: string]: any;
}

export const EmailInput = React.forwardRef(
  (props: EmailInputProps, ref: any) => {
    const { placeholder, label, className, isDisabled } = props;
    const autoCompleteValues = props.value || [];

    const isEmailInList = (email: string): boolean => {
      return autoCompleteValues.find((v) => v.value === email);
    };

    /**
     * MuiAutocomplete onChange send the entire new array as a new value
     * e.q. adding '3' to existing value of ['1', '2'] will send to onChange the newValue of ['1', '2', '3']
     * */
    const onChange = (_, newValue) => {
      const isNewElementAdded = newValue.length > props.value.length;

      if (isNewElementAdded) {
        const newElement = newValue[newValue.length - 1];

        if (!(validEmail(newElement) && !isEmailInList(newElement))) {
          return;
        }
      }

      props.onChange(
        newValue.map((v) => (v.value ? v : { label: v, value: v }))
      );
    };

    const onBlur = (ev) => {
      onChange(null, [...autoCompleteValues, ev.target.value]);
    };

    return (
      <>
        <Autocomplete
          freeSolo={true} // allows new options to be created
          value={props.value}
          onChange={onChange}
          onBlur={onBlur}
          multiple
          options={[]}
          getOptionLabel={(option) => option?.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          className={className}
          disabled={isDisabled}
          closeIcon={
            <IconClose
              className="marapp-qa-email-input-clear"
              fontSize="small"
            />
          }
          ChipProps={{ className: `marapp-qa-email-input-chip` }}
        />
      </>
    );
  }
);
