import React, { useState } from 'react';
import noop from 'lodash/noop';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { setupErrors } from '../../validations';
import { Input } from '../input';

interface IProps {
  open: boolean;
  onCancel: () => void;
  onSubmitFeedback: (values: { subject: string; message: string }) => Promise<any>;
}

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function FeedbackDialog(props: IProps) {
  const { open, onCancel = noop, onSubmitFeedback } = props;
  const [successFeedback, setSuccessFeedback] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit, register, errors, formState, control } = useForm({
    mode: 'all',
    defaultValues: {
      subject: '',
      message: '',
    },
  });
  const { touched, isDirty, isValid, isSubmitting } = formState;
  const renderErrorFor = setupErrors(errors, touched);

  return (
    <>
      <Dialog open={open} className="marapp-qa-feedback-dialog">
        <form onSubmit={handleSubmit(submitAndClose)}>
          <DialogTitle disableTypography={true}>
            <Typography variant="h5">{t('We love hearing your feedback')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography paragraph>
              {t(
                'Send us a message for how we can improve our build  to make our product even more awesome for you!'
              )}
            </Typography>
            <Box mb={2}>
              <Controller
                as={Input}
                className="marapp-qa-feedback-subject"
                label={t('Subject')}
                control={control}
                required={true}
                error={renderErrorFor('subject')}
                name="subject"
                autofocus={true}
                inputRef={register({
                  required: t('Subject is required') as string,
                })}
              />
            </Box>
            <Controller
              as={Input}
              className="marapp-qa-feedback-message"
              label={t('Message')}
              control={control}
              required={true}
              multiline={true}
              rows={6}
              error={renderErrorFor('message')}
              name="message"
              inputRef={register({
                required: t('Message is required') as string,
              })}
            />
            {serverError && (
              <Box py={2}>
                <Typography className="marapp-qa-create-collection-error" color="error">
                  {serverError}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Box pb={2}>
              <Button
                className="marapp-qa-save-collection"
                variant="contained"
                color="secondary"
                type="submit"
                endIcon={isSubmitting && <CircularProgress size={16} />}
                disabled={!isValid || !isDirty || isSubmitting}
              >
                {t('Send')}
              </Button>
              <Button onClick={cancelAndClearErr}>{t('Cancel')}</Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={successFeedback}
        autoHideDuration={6000}
        onClose={() => setSuccessFeedback(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={t('Thank you for your feedback.')}
      />
    </>
  );

  async function submitAndClose(values) {
    try {
      await onSubmitFeedback(values);
      setSuccessFeedback(true);
      cancelAndClearErr();
    } catch (err) {
      setServerError(t('Something went wrong'));
    }
  }

  function cancelAndClearErr() {
    onCancel();
    setServerError(null);
  }
}
