/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import i18n from "i18next";
import type { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";

import { Elang } from "../locales";
import SessionStorage from "../services/SessionStorage";

import translationEN from "./en/translation.json";
import translationES from "./es/translation.json";
import translationFR from "./fr/translation.json";
import translationPT from "./pt/translation.json";
import translationRU from "./ru/translation.json";
import translationENAdmin from "./en/admin.translation.json";
import translationESAdmin from "./es/admin.translation.json";
import translationFRAdmin from "./fr/admin.translation.json";
import translationPTAdmin from "./pt/admin.translation.json";
import translationRUAdmin from "./ru/admin.translation.json";
import translationENUNBL from "./en/unbl.translation.json";
import translationESUNBL from "./es/unbl.translation.json";
import translationFRUNBL from "./fr/unbl.translation.json";
import translationPTUNBL from "./pt/unbl.translation.json";
import translationRUUNBL from "./ru/unbl.translation.json";

import * as weglot from "./weglot";

interface ITranslationService {
  init(key: string): void;
  getDefaultLanguage(): string;
}

class TranslationService implements ITranslationService {
  constructor() {}

  getDefaultLanguage() {
    const { fallbackLng: defaultLanguage } = i18n.options;

    return <string>defaultLanguage || Elang.EN;
  }

  initOptions(preferredLanguageCode: string) {
    return {
      resources: {
        [Elang.EN]: {
          translation: translationEN,
          admin: translationENAdmin,
          unbl: translationENUNBL,
        },
        [Elang.ES]: {
          translation: translationES,
          admin: translationESAdmin,
          unbl: translationESUNBL,
        },
        [Elang.FR]: {
          translation: translationFR,
          admin: translationFRAdmin,
          unbl: translationFRUNBL,
        },
        [Elang.PT]: {
          translation: translationPT,
          admin: translationPTAdmin,
          unbl: translationPTUNBL,
        },
        [Elang.RU]: {
          translation: translationRU,
          admin: translationRUAdmin,
          unbl: translationRUUNBL,
        },
      },
      lng: preferredLanguageCode,
      fallbackLng: Elang.EN,
      fallbackNS: "translation",
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      // debug emits a harmless message onto the console when a translation is
      // not found.
      // debug: true,
    } as InitOptions;
  }

  init(weglotApiKey) {
    const preferredLanguageCode = SessionStorage.get("lang") || Elang.EN;

    i18n.on("initialized", async (options) => {
      await weglot.init(weglotApiKey);

      weglot.changeLanguage(options.lng);
    });

    const i18nOptions = this.initOptions(preferredLanguageCode);

    i18n.use(initReactI18next).init(i18nOptions);

    i18n.on("languageChanged", (lng) => {
      weglot.changeLanguage(lng);

      SessionStorage.add("lang", lng);
    });
  }
}

export default new TranslationService();
