import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import React from 'react';

interface IMapControls {
  children: any;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(3),
    zIndex: 999,
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const MapControls = (props: IMapControls) => {
  const { className, children } = props;
  const classes = useStyles();
  const classNames = classnames(classes.root, 'marapp-qa-mapcontrols', {
    [className]: !!className,
  });

  return (
    <Box className={classNames}>
      {React.Children.map(
        children,
        (c, i) =>
          React.isValidElement(c) && (
            <Box key={i} pl={1}>
              {React.cloneElement(c)}
            </Box>
          )
      )}
    </Box>
  );
};

export default MapControls;
