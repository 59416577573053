import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classnames from 'classnames';
import IconMinus from 'mdi-material-ui/Minus';
import IconPlus from 'mdi-material-ui/Plus';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey['600'],
    boxShadow: theme.shadows[2],
    '& button': {
      borderColor: 'transparent',
      minWidth: 0,
      padding: theme.spacing(0.5),
      '&:hover': {
        backgroundColor: theme.palette.grey['500'],
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  },
}));

interface IZoomControl {
  viewport: { zoom?: number; maxZoom?: number; minZoom?: number };
  className?: string;
  onClick: (zoom: number) => void;
  zoom?: number;
}

const ZoomControl = (props: IZoomControl) => {
  const { className, viewport, onClick } = props;
  const { maxZoom, minZoom, zoom } = viewport;
  const classes = useStyles();

  const classNames = classnames('marapp-qa-mapzoom', classes.root, {
    [className]: !!className,
  });

  const increaseZoom = (e) => {
    e.stopPropagation();

    onClick(zoom === maxZoom ? zoom : zoom + 1);
  };

  const decreaseZoom = (e) => {
    e.stopPropagation();

    onClick(zoom === minZoom ? zoom : zoom - 1);
  };

  return (
    <ButtonGroup orientation="vertical" className={classNames}>
      <Button
        className="marapp-qa-zoomin"
        disabled={zoom === maxZoom}
        onClick={increaseZoom}
        size="small"
      >
        <IconPlus fontSize="small" />
      </Button>

      <Button
        className="marapp-qa-zoomout"
        disabled={zoom === minZoom}
        onClick={decreaseZoom}
        size="small"
      >
        <IconMinus fontSize="small" />
      </Button>
    </ButtonGroup>
  );
};

export default ZoomControl;
