import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import IconInfo from 'mdi-material-ui/Information';
import React from 'react';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey['600'],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.grey['500'],
    },
  },
});

export interface IInfo {
  className?: string;
  classes?: any;
}

class InfoControl extends React.PureComponent<IInfo> {
  public static defaultProps = {
    className: null,
  };

  public render() {
    const { className, classes } = this.props;

    const classNames = classnames('marapp-qa-infocontrol', classes.root, {
      [className]: !!className,
    });

    return <IconInfo xmlns={'http://www.w3.org/2000/svg'} htmlColor={'currentColor'} />;
  }
}

export default withStyles(styles)(InfoControl);
