import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import IconRecenter from 'mdi-material-ui/CrosshairsGps';
import React from 'react';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.grey['600'],
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.grey['500'],
    },
  },
});

export interface IRecenter {
  className?: string;
  classes?: any;
  onClick: () => void;
}

class RecenterControl extends React.PureComponent<IRecenter> {
  public static defaultProps = {
    className: null,
  };

  public render() {
    const { className, classes, onClick } = this.props;

    const classNames = classnames('marapp-qa-recentercontrol', classes.root, {
      [className]: !!className,
    });

    return (
      <Box mb={0.8}>
        <ButtonBase onClick={onClick}>
          <Paper className={classNames}>
            <Box p={0.6}>
              <IconRecenter fontSize="small" />
            </Box>
          </Paper>
        </ButtonBase>
      </Box>
    );
  }
}

export default withStyles(styles)(RecenterControl);
