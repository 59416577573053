/*
 * Licensed under the Apache License, Version 2.0 (the "License"); you may not use
 * this file except in compliance with the License. You may obtain a copy of the
 * License at
 *
 *  https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed
 * under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the
 * specific language governing permissions and limitations under the License.
 */

import { groupBy, sortBy } from "lodash";

interface Filter {
  key: string;
  value?: string;
  count: number;
}

export const TRANSFORM = {
  groupFilters(response) {
    const transformer = (payload: Filter[]) => {
      const filtersWithLabels = payload.map((filter: Filter) => {
        const filterPath = filter.value.split("/");
        return {
          ...filter,
          value: filter.value || "",
          label: filter.value || "",
          path: filterPath,
          ...(filter.key === "category" &&
            filter.value &&
            filterPath.length > 1 && {
              label: filterPath[filterPath.length - 1],
            }),
          ...(typeof filter.value === "boolean" && {
            label: filter.value ? "Yes" : "No",
            value: filter.value ? "true" : "false",
          }),
        };
      });

      const availableFilters = groupBy(
        sortBy(filtersWithLabels, "value"),
        "key"
      );
      return availableFilters;
    };

    const result = {
      ...response,
      meta: {
        ...response.meta,
        filters: transformer(response.meta.filters),
      },
    };

    return result;
  },

  flattenLayers(response) {
    return {
      ...response,
      data: response.data.map(flattenLayerConfig),
    };
  },
  STACCatalogId(response) {
    return {
      ...response,
      data: response.data.id,
    };
  },
  STACCollectionsIds(response) {
    return {
      ...response,
      data: response.data.collection_ids,
    };
  },
  STACCollectionAssets(response) {
    return {
      ...response,
      data: Object.keys(response.data.item_ids),
    };
  },
};

/**
 * Put layer.config props directly on the layer - include layer reference
 */
// TODO: add (layer: ILayerRaw) types when we move them into shared
export function flattenLayerConfig(layer: any) {
  const adaptedLayer = flattenEachLayerConfig(layer);

  if (!!adaptedLayer?.references?.length) {
    const adaptedReferences = layer.references.map(flattenEachLayerConfig);

    return {
      ...adaptedLayer,
      references: adaptedReferences,
    };
  }

  return {
    ...adaptedLayer,
  };
}

/**
 * Put layer.config props directly on the layer
 */
// TODO: add (layer: ILayerRaw): ILayer types when we move them into shared
function flattenEachLayerConfig(layer: any): any {
  const { config, ...rest } = layer;
  return {
    ...rest,
    ...config,
  };
}
